<template>
  <div id="form">

    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <!-- :class="{ 'is-5-tablet is-4-desktop is-3-widescreen': step == 1 || step == 2, 'is-6-tablet is-5-desktop is-4-widescreen': step == 3 }" -->
              <div class="box" autocomplete="off">
                <div class="field">
                  <img src="/tnj-logo.png" alt="tnj" width="150" @click="redirect('/')">
                  <label for="" class="label">Sign up</label>
                  <p v-if="step == 1">
                    <small>Please enter Model / Mac Address</small>
                  </p>
                  <p v-if="step !== 1">
                    <small><b>Model:</b> {{ input_mac }} </small>
                  </p>
                  <div v-if="step == 3">
                    <small>
                      <b>Email:</b> {{ email }}
                      <br>
                      <b>Name:</b> {{ fname }} {{ lname }}
                    </small>
                    <hr>
                  </div>
                </div>
                <form @submit.prevent="submit_model" v-if="step == 1">
                  <div class="field-body">
                    <div class="field has-addons">
                      <p class="control">
                        <span class="select">
                          <select v-model="input_type" required>
                            <option value="" disabled>Model</option>
                            <option value="1">TNJ_SM- </option>
                            <option value="2">TNJ_SC- </option>
                            <option value="3">TNJ_SL- </option>
                          </select>
                        </span>
                      </p>
                      <p class="control is-expanded">
                        <input class="input" type="text" placeholder="Mac Address" v-model="input_mac" required>
                      </p>
                    </div>
                  </div>
                  <div class="field">
                    <button type="submit" class="button is-success is-fullwidth" :class="{ 'is-loading': is_loading }">
                      Submit
                    </button>
                  </div>
                  <div class="field" v-if="see_contact">
                    <small>
                      If you don't have model
                      <br>
                      please contact support
                      <br>
                      Tel: (+66) 02-449-7168
                      <br>
                      <img src="/TNJ_LINE.jpg" alt="line" width="175">
                      <!-- LineID: 088433356 -->
                    </small>
                  </div>
                  <div class="field" v-else @click="see_contact = true">
                    <small class="cursor_pointer has-text-link"> I don't have model. </small>
                  </div>
                </form>
                <form @submit.prevent="submit_account" v-if="step == 2">
                  <div class="field mb-1">
                    <div class="control" :class="{ 'is-loading': is_loading }">
                      <input type="text" v-model="fname" class="input has-text-centered" placeholder="First Name"
                        required>
                    </div>
                  </div>
                  <div class="field mb-1">
                    <div class="control" :class="{ 'is-loading': is_loading }">
                      <input type="text" v-model="lname" class="input has-text-centered" placeholder="Last Name" required>
                    </div>
                  </div>
                  <div class="field mb-1">
                    <div class="control" :class="{ 'is-loading': is_loading }">
                      <input type="email" v-model="email" class="input has-text-centered" placeholder="Email Address"
                        required>
                    </div>
                  </div>
                  <div class="field mb-1">
                    <div class="control" :class="{ 'is-loading': is_loading }">
                      <input :type="see_password ? 'text' : 'password'" v-model="password" @focus="see_password = true"
                        @blur="see_password = false" class="input has-text-centered" placeholder="Password" required>
                    </div>
                  </div>
                  <div class="field mb-1">
                    <div class="control" :class="{ 'is-loading': is_loading }">
                      <input :type="see_c_password ? 'text' : 'password'" v-model="c_password"
                        @focus="see_c_password = true" @blur="see_c_password = false" class="input has-text-centered"
                        placeholder="Confirm Password" required>
                    </div>
                  </div>
                  <div class="field mb-1">
                    <button type="submit" class="button is-success is-fullwidth" :class="{ 'is-loading': is_loading }">
                      Submit
                    </button>
                  </div>
                </form>
                <form @submit.prevent="submit_sign_up" v-if="step == 3">
                  <!-- <div class="field mb-1">
                    <div class="control" :class="{ 'is-loading': is_loading }">
                      <input type="text" v-model="input_code" class="input has-text-centered"
                        placeholder="Please check email and enter the code" required>
                    </div>
                  </div> -->
                  <div class="field mb-1">
                    <button type="submit" class="button is-success is-fullwidth" :class="{ 'is-loading': is_loading }">
                      Create Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Register',
  data() {
    return {
      see_contact: false,
      see_password: false,
      see_c_password: false,
      step: 1,
      // ......................................................................
      input_type: "",
      input_mac: "",
      // ......................................................................
      fname: "",
      lname: "",
      email: "",
      password: "",
      c_password: "",
      // ......................................................................
      code: "",
      input_code: "",
      // ......................................................................
      is_loading: false,
    }
  },
  mounted() {
    this.code = this.randomCode(6);
  },
  methods: {
    randomCode(length) {
      var result = '';
      // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var characters = '0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    async submit_model() {
      if (this.is_loading) return false;

      if (!this.input_type.length || !this.input_mac.length) {
        this.$swal("", "Please enter model", "warning");
        return false;
      }

      this.is_loading = true;
      var url = this.api + 'register/check_model/' + this.secret;
      var data = new FormData();
      data.set('input_type', this.input_type);
      data.set('input_mac', this.input_mac);
      data.set('secret', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.step = 2;
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    async submit_account() {
      if (!this.fname.length) {
        this.$swal("", "Please enter first name!", "warning");
        return false;
      } else if (!this.lname.length) {
        this.$swal("", "Please enter last name!", "warning");
        return false;
      } else if (!this.email.length) {
        this.$swal("", "Please enter email!", "warning");
        return false;
      } else if (!this.validEmail(this.email)) {
        this.$swal("", "Invalid email address!", "warning");
        return false;
      } else if (!this.password.length) {
        this.$swal("", "Please enter password!", "warning");
        return false;
      } else if (!this.c_password.length) {
        this.$swal("", "Please confirm password!", "warning");
        return false;
      } else if (this.password !== this.c_password) {
        this.$swal("", "Password not matched!", "warning");
        return false;
      } else {
        this.check_email();
      }
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async check_email() {
      if (this.is_loading) return false;

      this.is_loading = true;
      var url = this.api + 'register/check_email/' + this.secret;
      var data = new FormData();
      data.set('email', this.email);
      data.set('secret', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.step = 3;
            this.send_register_code();
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    async send_register_code() {
      var url = this.api + 'email/send_register_code/' + this.secret;
      var data = new FormData();
      data.set('email', this.email);
      data.set('code', this.code);
      data.set('fullname', this.fname + " " + this.lname);
      data.set('type', this.input_type);
      data.set('mac', this.input_mac);
      data.set('secret', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            console.log(this.code);
          } else {
            this.$swal("", response.data.data, "error");
          }
        })
        .catch((error) => {
          this.$swal("", "", "error");
          console.error(error);
        })
    },
    async submit_sign_up() {
      if (this.is_loading) return false;

      // if (this.code !== this.input_code) {
      //   this.$swal("", "The code isn't correct!", "error");
      //   return false;
      // }

      this.is_loading = true;
      var url = this.api + 'register/sign_up/' + this.secret;
      var data = new FormData();
      data.set('email', this.email);
      data.set('password', this.password);
      data.set('fname', this.fname);
      data.set('lname', this.lname);
      data.set('type', this.input_type);
      data.set('mac', this.input_mac);
      data.set('secret', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.$swal("Successful Login", "", "success", { timer: 1000 });

            this.save_token(response.data.data, this.password);
            this.redirect("/member");
          } else {
            this.$swal("", response.data.data, "warning");
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
  }
}
</script>

<style>
#form {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #ffffff 0%, #edf0ff 100%);
  overflow: hidden;
}
</style>
